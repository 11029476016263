
import { ModelConnection } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({
  name: 'ModelConnectionPreview'
})
export default class extends Vue {
  @Prop() readonly connection!: ModelConnection
  @Prop({ type: Boolean }) readonly flip!: boolean
}
