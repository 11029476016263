
import { objectConnectionPoints } from '@icepanel/app-graphics'
import { DiagramConnection, DiagramConnectionPartial, DiagramContentPartial, DiagramObjectConnector, ModelConnection, ModelConnectionRequired, Task } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Location } from 'vue-router'
import { getModule } from 'vuex-module-decorators'

import Dialog from '@/components/dialog.vue'
import { AlertModule } from '@/modules/alert/store'
import { DiagramModule } from '@/modules/diagram/store'
import { EditorModule } from '@/modules/editor/store'
import { FlowModule } from '@/modules/flow/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { ShareModule } from '@/modules/share/store'
import { TeamModule } from '@/modules/team/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../helpers/analytics'
import ObjectPreview from '../objects/object-preview.vue'
import ConnectionPreview from './connection-preview.vue'

@Component({
  components: {
    ConnectionPreview,
    Dialog,
    ObjectPreview
  },
  name: 'ModelConnectionReassignDialog'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  diagramModule = getModule(DiagramModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  flowModule = getModule(FlowModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  teamModule = getModule(TeamModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  loadingNew = false
  loadingModel = false

  connectionCache: DiagramConnection | null = null
  connectionModelCache: ModelConnection | null = null
  originIdCache: string | null = null
  targetIdCache: string | null = null

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || 'latest'
  }

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get connectionReassignDialog () {
    return this.$queryValue('connection_reassign_dialog')
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentLandscapePermission () {
    return this.landscapeModule.landscapePermission(this.currentLandscape)
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.currentDiagramHandleId)!
  }

  get currentDiagramContent () {
    return Object.values(this.diagramModule.diagramContents).find(o => o.handleId === this.currentDiagramHandleId)!
  }

  get connection () {
    return this.connectionCache || (this.connectionId ? this.currentDiagramContent?.connections[this.connectionId] : undefined)
  }

  get connectionModel () {
    return this.connectionModelCache || (this.connection?.modelId ? this.modelModule.connections[this.connection.modelId] : undefined)
  }

  get connectionModelDiagrams () {
    return Object.values(this.connectionModel?.diagrams || {})
  }

  get connectionModelFlows () {
    return Object.values(this.connectionModel?.flows || {})
  }

  get diagramConnection () {
    return this.connectionModel && this.currentDiagram ? this.connectionModel.diagrams[this.currentDiagram.id] : undefined
  }

  get connectionIsLower () {
    return this.diagramConnection && (this.connectionModel?.originId !== this.diagramConnection.originModelId || this.connectionModel?.targetId !== this.diagramConnection.targetModelId)
  }

  get connectionInstanceLink (): Location | undefined {
    const connectionModel = this.connectionModel
    if (!connectionModel) {
      return
    }

    const directConnection = Object.values(connectionModel.diagrams).find(o => o.originModelId === connectionModel.originId && o.targetModelId === connectionModel.targetId)
    const directConnectionDiagram = directConnection ? this.diagramModule.diagrams[directConnection.id] : undefined
    const directConnectionDiagramModel = directConnectionDiagram ? this.modelModule.objects[directConnectionDiagram.modelId] : undefined

    if (directConnection && directConnectionDiagram && directConnectionDiagramModel) {
      return {
        name: 'editor-diagram',
        query: this.$setQuery({
          connection: directConnection.connectionId,
          connection_reassign_dialog: undefined,
          diagram: directConnectionDiagram.handleId,
          flow: undefined,
          flow_parent: undefined,
          flow_path: undefined,
          flow_step: undefined,
          model: directConnectionDiagramModel.handleId,
          object: undefined,
          x1: undefined,
          x2: undefined,
          y1: undefined,
          y2: undefined
        })
      }
    }
  }

  get connectionId () {
    return this.connectionReassignDialog?.split('_')?.[0]
  }

  get originId () {
    return this.originIdCache || this.connectionReassignDialog?.split('_')?.[1]
  }

  get originConnector () {
    return this.connectionReassignDialog?.split('_')?.[2] as DiagramObjectConnector || undefined
  }

  get targetId () {
    return this.targetIdCache || this.connectionReassignDialog?.split('_')?.[3]
  }

  get targetConnector () {
    return this.connectionReassignDialog?.split('_')?.[4] as DiagramObjectConnector || undefined
  }

  get currentOrigin () {
    return this.connection?.originId ? this.currentDiagramContent?.objects[this.connection.originId] : undefined
  }

  get currentOriginModel () {
    return this.currentOrigin?.modelId ? this.modelModule.objects[this.currentOrigin.modelId] : undefined
  }

  get currentTarget () {
    return this.connection?.targetId ? this.currentDiagramContent?.objects[this.connection.targetId] : undefined
  }

  get currentTargetModel () {
    return this.currentTarget?.modelId ? this.modelModule.objects[this.currentTarget.modelId] : undefined
  }

  get newOrigin () {
    if (this.originId && this.newOriginModel && this.currentDiagramContent) {
      const modelObjectFamilyIds = [this.newOriginModel.id, ...this.modelModule.objects[this.newOriginModel.id]?.parentIds || []]
      return Object.values(this.currentDiagramContent.objects).find(o => o.modelId === this.newOriginModel?.id) || Object.values(this.currentDiagramContent.objects).find(o => modelObjectFamilyIds.includes(o.modelId))
    } else {
      return this.currentOrigin
    }
  }

  get newTarget () {
    if (this.targetId && this.newTargetModel && this.currentDiagramContent) {
      const modelObjectFamilyIds = [this.newTargetModel.id, ...this.modelModule.objects[this.newTargetModel.id]?.parentIds || []]
      return Object.values(this.currentDiagramContent.objects).find(o => o.modelId === this.newTargetModel?.id) || Object.values(this.currentDiagramContent.objects).find(o => modelObjectFamilyIds.includes(o.modelId))
    } else {
      return this.currentTarget
    }
  }

  get newOriginModel () {
    return this.originId ? this.modelModule.objects[this.originId] : this.currentOriginModel
  }

  get newTargetModel () {
    return this.targetId ? this.modelModule.objects[this.targetId] : this.currentTargetModel
  }

  get isOriginProtected (): boolean {
    if (this.currentLandscapePermission === 'admin') { return false }
    const currentOriginModel = this.currentOriginModel
    if (!currentOriginModel) { return false }
    return (
      currentOriginModel &&
      currentOriginModel.teamOnlyEditing &&
      !!currentOriginModel.teamIds.length &&
      !this.teamModule.userTeams.some(o => currentOriginModel.teamIds.includes(o.id))
    )
  }

  opened () {
    analytics.modelConnectionReassignDialog.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId]
    })
  }

  closed () {
    this.connectionCache = null
    this.connectionModelCache = null
    this.originIdCache = null
    this.targetIdCache = null
  }

  async newConnection () {
    try {
      this.loadingNew = true

      this.connectionCache = this.connection || null
      this.originIdCache = this.originId || null
      this.targetIdCache = this.targetId || null
      this.connectionModelCache = this.connectionModel || null

      const currentDiagramContent = this.currentDiagramContent
      if (this.connection && this.connectionModel) {
        const tasks: Task[] = []
        const revertTasks: Task[] = []

        if (this.originId && this.newOriginModel && this.newOrigin) {
          revertTasks.push({
            route: {
              ...this.$route,
              query: {
                ...this.$route.query,
                connection_reassign_dialog: null
              }
            },
            type: 'navigation'
          })

          const connections = { ...currentDiagramContent.connections }

          const diagramProps: DiagramContentPartial = {}
          const revertDiagramProps: DiagramContentPartial = {}

          const connectionCreate: ModelConnectionRequired = {
            description: '',
            direction: this.connectionModel.direction,
            name: this.connectionModel.name,
            originId: this.newOriginModel.id,
            tagIds: [],
            targetId: this.connectionIsLower && this.currentTargetModel ? this.currentTargetModel.id : this.connectionModel.targetId
          }
          const { connection, connectionUpsert } = this.modelModule.generateConnection(this.currentLandscape.id, this.currentVersion.id, connectionCreate)
          if (this.currentDiagram.status === 'draft') {
            revertDiagramProps.tasksProposed = {
              ...revertDiagramProps.tasksProposed,
              $append: [
                ...revertDiagramProps.tasksProposed?.$append || [],
                {
                  id: connection.id,
                  type: 'model-connection-delete'
                }
              ]
            }
            diagramProps.tasksProposed = {
              ...diagramProps.tasksProposed,
              $append: [
                ...diagramProps.tasksProposed?.$append || [],
                {
                  id: connection.id,
                  props: connectionUpsert,
                  type: 'model-connection-create'
                }
              ]
            }
          } else {
            this.modelModule.setConnection(connection)
            await this.modelModule.connectionUpsert({
              connectionId: connection.id,
              landscapeId: this.currentLandscape.id,
              props: connectionUpsert,
              versionId: this.currentVersion.id
            })

            tasks.push({
              id: connection.id,
              props: connectionCreate,
              type: 'model-connection-create'
            })
          }

          revertDiagramProps.connections = {
            ...revertDiagramProps.connections,
            $update: {
              ...revertDiagramProps.connections?.$update,
              [this.connection.id]: {
                modelId: this.connection.modelId,
                originConnector: this.connection.originConnector,
                originId: this.connection.originId
              }
            }
          }
          const connectionUpdate: DiagramConnectionPartial = {
            modelId: connection.id,
            originConnector: this.originConnector || this.connection.originConnector,
            originId: this.newOrigin.id
          }
          connections[this.connection.id] = {
            ...this.connection,
            ...connectionUpdate
          }
          diagramProps.connections = {
            ...diagramProps.connections,
            $update: {
              ...diagramProps.connections?.$update,
              [this.connection.id]: connectionUpdate
            }
          }

          Object.entries(objectConnectionPoints(Object.values(currentDiagramContent.objects), Object.values(connections))).forEach(([id, points]) => {
            revertDiagramProps.connections = {
              ...revertDiagramProps.connections,
              $update: {
                ...revertDiagramProps.connections?.$update,
                [id]: {
                  ...revertDiagramProps.connections?.$update?.[id],
                  points: connections[id].points
                }
              }
            }
            connections[id] = {
              ...connections[id],
              points
            }
            diagramProps.connections = {
              ...diagramProps.connections,
              $update: {
                ...diagramProps.connections?.$update,
                [id]: {
                  ...diagramProps.connections?.$update?.[id],
                  points
                }
              }
            }
          })

          revertTasks.push({
            id: currentDiagramContent.id,
            props: revertDiagramProps,
            type: 'diagram-content-update'
          })

          const { diagramContent, diagramContentUpdate, modelConnectionDiagramAdd, modelConnectionDiagramRemove } = this.diagramModule.generateDiagramContentCommit(currentDiagramContent.id, diagramProps)
          this.diagramModule.setDiagramContentVersion(diagramContent)
          this.modelModule.setConnectionDiagrams({ modelConnectionDiagramAdd, modelConnectionDiagramRemove })
          await this.diagramModule.diagramContentUpdate({
            diagramId: currentDiagramContent.id,
            landscapeId: this.currentLandscape.id,
            props: diagramContentUpdate,
            versionId: this.currentVersion.id
          })

          tasks.push({
            id: diagramContent.id,
            props: diagramContentUpdate,
            type: 'diagram-content-update'
          })

          if (this.currentDiagram.status !== 'draft') {
            revertTasks.push({
              id: connection.id,
              type: 'model-connection-delete'
            })
          }
        } else if (this.targetId && this.newTargetModel && this.newTarget) {
          revertTasks.push({
            route: {
              ...this.$route,
              query: {
                ...this.$route.query,
                connection_reassign_dialog: null
              }
            },
            type: 'navigation'
          })

          const diagramProps: DiagramContentPartial = {}
          const revertDiagramProps: DiagramContentPartial = {}

          const connections = { ...currentDiagramContent.connections }

          const connectionCreate: ModelConnectionRequired = {
            description: '',
            direction: this.connectionModel.direction,
            name: this.connectionModel.name,
            originId: this.connectionIsLower && this.currentOriginModel ? this.currentOriginModel.id : this.connectionModel.originId,
            tagIds: [],
            targetId: this.newTargetModel.id
          }
          const { connection, connectionUpsert } = this.modelModule.generateConnection(this.currentLandscape.id, this.currentVersion.id, connectionCreate)
          if (this.currentDiagram.status === 'draft') {
            revertDiagramProps.tasksProposed = {
              ...revertDiagramProps.tasksProposed,
              $append: [
                ...revertDiagramProps.tasksProposed?.$append || [],
                {
                  id: connection.id,
                  type: 'model-connection-delete'
                }
              ]
            }
            diagramProps.tasksProposed = {
              ...diagramProps.tasksProposed,
              $append: [
                ...diagramProps.tasksProposed?.$append || [],
                {
                  id: connection.id,
                  props: connectionUpsert,
                  type: 'model-connection-create'
                }
              ]
            }
          } else {
            this.modelModule.setConnection(connection)
            await this.modelModule.connectionUpsert({
              connectionId: connection.id,
              landscapeId: this.currentLandscape.id,
              props: connectionUpsert,
              versionId: this.currentVersion.id
            })

            tasks.push({
              id: connection.id,
              props: connectionCreate,
              type: 'model-connection-create'
            })
          }

          revertDiagramProps.connections = {
            ...revertDiagramProps.connections,
            $update: {
              ...revertDiagramProps.connections?.$update,
              [this.connection.id]: {
                modelId: this.connection.modelId,
                targetConnector: this.connection.targetConnector,
                targetId: this.connection.targetId
              }
            }
          }
          const connectionUpdate: DiagramConnectionPartial = {
            modelId: connection.id,
            targetConnector: this.targetConnector || this.connection.targetConnector,
            targetId: this.newTarget.id
          }
          connections[this.connection.id] = {
            ...this.connection,
            ...connectionUpdate
          }
          diagramProps.connections = {
            ...diagramProps.connections,
            $update: {
              ...diagramProps.connections?.$update,
              [this.connection.id]: connectionUpdate
            }
          }

          Object.entries(objectConnectionPoints(Object.values(currentDiagramContent.objects), Object.values(connections))).forEach(([id, points]) => {
            revertDiagramProps.connections = {
              ...revertDiagramProps.connections,
              $update: {
                ...revertDiagramProps.connections?.$update,
                [id]: {
                  ...revertDiagramProps.connections?.$update?.[id],
                  points: connections[id].points
                }
              }
            }
            connections[id] = {
              ...connections[id],
              points
            }
            diagramProps.connections = {
              ...diagramProps.connections,
              $update: {
                ...diagramProps.connections?.$update,
                [id]: {
                  ...diagramProps.connections?.$update?.[id],
                  points
                }
              }
            }
          })

          revertTasks.push({
            id: currentDiagramContent.id,
            props: revertDiagramProps,
            type: 'diagram-content-update'
          })

          const { diagramContent, diagramContentUpdate, modelConnectionDiagramAdd, modelConnectionDiagramRemove } = this.diagramModule.generateDiagramContentCommit(currentDiagramContent.id, diagramProps)
          this.diagramModule.setDiagramContentVersion(diagramContent)
          this.modelModule.setConnectionDiagrams({ modelConnectionDiagramAdd, modelConnectionDiagramRemove })
          await this.diagramModule.diagramContentUpdate({
            diagramId: currentDiagramContent.id,
            landscapeId: this.currentLandscape.id,
            props: diagramContentUpdate,
            versionId: this.currentVersion.id
          })

          tasks.push({
            id: diagramContent.id,
            props: diagramContentUpdate,
            type: 'diagram-content-update'
          })

          if (this.currentDiagram.status !== 'draft') {
            revertTasks.push({
              id: connection.id,
              type: 'model-connection-delete'
            })
          }
        }

        this.editorModule.addTaskList({
          revertTasks,
          tasks
        })

        if (this.currentDiagram) {
          await this.diagramModule.diagramContentFind({
            diagramId: this.currentDiagram.id,
            landscapeId: this.currentLandscape.id,
            versionId: this.currentVersion.id
          })
        }

        analytics.modelConnectionReassignNew.track(this, {
          landscapeId: [this.currentLandscape.id],
          organizationId: [this.currentLandscape.organizationId]
        })

        await this.$replaceQuery({
          connection_reassign_dialog: undefined
        })
      }
    } catch (err: any) {
      this.alertModule.pushAlert({
        color: 'error',
        message: err.message
      })
    } finally {
      this.connectionCache = null
      this.connectionModelCache = null
      this.originIdCache = null
      this.targetIdCache = null

      this.loadingNew = false
    }
  }

  async changeModel () {
    try {
      this.loadingModel = true

      this.connectionCache = this.connection || null
      this.connectionModelCache = this.connectionModel || null
      this.originIdCache = this.originId || null
      this.targetIdCache = this.targetId || null

      if (this.connectionModel && this.newOriginModel && this.newTargetModel) {
        const revertDiagramProps: DiagramContentPartial = {}
        const diagramProps: DiagramContentPartial = {}

        if (this.connection) {
          const connections = window.structuredClone(this.currentDiagramContent.connections)

          if (this.newOrigin && this.currentOrigin && this.newOrigin !== this.currentOrigin) {
            revertDiagramProps.connections = {
              ...revertDiagramProps.connections,
              $update: {
                ...revertDiagramProps.connections?.$update,
                [this.connection.id]: {
                  originConnector: this.connection.originConnector,
                  originId: this.connection.originId
                }
              }
            }
            connections[this.connection.id] = {
              ...connections[this.connection.id],
              originConnector: this.originConnector || this.connection.originConnector,
              originId: this.newOrigin.id
            }
            diagramProps.connections = {
              ...diagramProps.connections,
              $update: {
                ...diagramProps.connections?.$update,
                [this.connection.id]: {
                  originConnector: this.originConnector || this.connection.originConnector,
                  originId: this.newOrigin.id
                }
              }
            }
          } else if (this.newTarget && this.currentTarget && this.newTarget !== this.currentTarget) {
            revertDiagramProps.connections = {
              ...revertDiagramProps.connections,
              $update: {
                ...revertDiagramProps.connections?.$update,
                [this.connection.id]: {
                  targetConnector: this.connection.targetConnector,
                  targetId: this.connection.targetId
                }
              }
            }
            connections[this.connection.id] = {
              ...connections[this.connection.id],
              targetConnector: this.targetConnector || this.connection.targetConnector,
              targetId: this.newTarget.id
            }
            diagramProps.connections = {
              ...diagramProps.connections,
              $update: {
                ...diagramProps.connections?.$update,
                [this.connection.id]: {
                  targetConnector: this.targetConnector || this.connection.targetConnector,
                  targetId: this.newTarget.id
                }
              }
            }
          }

          Object.entries(objectConnectionPoints(Object.values(this.currentDiagramContent.objects), Object.values(connections))).forEach(([id, points]) => {
            revertDiagramProps.connections = {
              ...revertDiagramProps.connections,
              $update: {
                ...revertDiagramProps.connections?.$update,
                [id]: {
                  ...revertDiagramProps.connections?.$update?.[id],
                  points: connections[id].points
                }
              }
            }
            connections[id] = {
              ...connections[id],
              points
            }
            diagramProps.connections = {
              ...diagramProps.connections,
              $update: {
                ...diagramProps.connections?.$update,
                [id]: {
                  ...diagramProps.connections?.$update?.[id],
                  points
                }
              }
            }
          })
        }

        if (this.currentDiagram.status === 'draft') {
          const tasks: Task[] = []
          const revertTasks: Task[] = []

          revertTasks.push({
            id: this.currentDiagram.id,
            props: {
              connections: revertDiagramProps.connections,
              tasksProposed: {
                $append: [{
                  id: this.connectionModel.id,
                  props: {
                    originId: this.connectionModel.originId === this.newOriginModel.id ? undefined : this.connectionModel.originId,
                    targetId: this.connectionModel.targetId === this.newTargetModel.id ? undefined : this.connectionModel.targetId
                  },
                  type: 'model-connection-update'
                }]
              }
            },
            type: 'diagram-content-update'
          }, {
            route: {
              ...this.$route,
              query: {
                ...this.$route.query,
                connection_reassign_dialog: null
              }
            },
            type: 'navigation'
          })

          const { diagramContent, diagramContentUpdate } = this.diagramModule.generateDiagramContentCommit(this.currentDiagram.id, {
            connections: diagramProps.connections,
            tasksProposed: {
              $append: [{
                id: this.connectionModel.id,
                props: {
                  originId: this.connectionModel.originId === this.newOriginModel.id ? undefined : this.newOriginModel.id,
                  targetId: this.connectionModel.targetId === this.newTargetModel.id ? undefined : this.newTargetModel.id
                },
                type: 'model-connection-update'
              }]
            }
          })
          await this.diagramModule.diagramContentUpdate({
            diagramId: diagramContent.id,
            landscapeId: this.currentLandscapeId,
            props: diagramContentUpdate,
            versionId: this.currentVersionId
          })

          tasks.push({
            id: this.currentDiagram.id,
            props: diagramContentUpdate,
            type: 'diagram-content-update'
          }, {
            route: {
              ...this.$route,
              query: {
                ...this.$route.query,
                connection_reassign_dialog: null
              }
            },
            type: 'navigation'
          })

          this.editorModule.addTaskList({
            revertTasks,
            tasks
          })
        } else {
          const { diagramContent, diagramContentUpdate, modelConnectionDiagramAdd, modelConnectionDiagramRemove } = this.diagramModule.generateDiagramContentCommit(this.currentDiagram.id, diagramProps)
          this.diagramModule.setDiagramContentVersion(diagramContent)
          this.modelModule.setConnectionDiagrams({ modelConnectionDiagramAdd, modelConnectionDiagramRemove })
          await this.diagramModule.diagramContentUpdate({
            diagramId: this.currentDiagram.id,
            landscapeId: this.currentLandscape.id,
            props: diagramContentUpdate,
            versionId: this.currentVersion.id
          })

          const { connection, connectionUpdate } = this.modelModule.generateConnectionCommit(this.connectionModel.id, {
            originId: this.connectionModel.originId === this.newOriginModel.id ? undefined : this.newOriginModel.id,
            targetId: this.connectionModel.targetId === this.newTargetModel.id ? undefined : this.newTargetModel.id
          })
          await this.modelModule.connectionUpdate({
            connectionId: connection.id,
            landscapeId: this.currentLandscape.id,
            props: connectionUpdate,
            versionId: this.currentVersion.id
          })

          this.editorModule.resetTaskLists()

          if (this.currentDiagram) {
            await this.diagramModule.diagramContentFind({
              diagramId: this.currentDiagram.id,
              landscapeId: this.currentLandscape.id,
              versionId: this.currentVersion.id
            })
          }

          analytics.modelConnectionReassignModel.track(this, {
            landscapeId: [this.currentLandscape.id],
            organizationId: [this.currentLandscape.organizationId]
          })
        }

        await this.$replaceQuery({
          connection_reassign_dialog: undefined
        })
      }
    } catch (err: any) {
      this.alertModule.pushAlert({
        color: 'error',
        message: err.message
      })
    } finally {
      this.loadingModel = false
    }
  }
}
